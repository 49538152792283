import React, { useEffect, useState } from 'react'
import { getAxios } from '../../service/apiservice';
import { reviewViewEndPoint } from '../../service/api';

function Viewreview({ currentReview }) {
  const [viewTypeDropdown, setViewTypeDropdown] = useState({});

  console.log("currentReview", currentReview)

  useEffect(() => {
    getStatusDropdownData()
  }, [])

  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: reviewViewEndPoint + currentReview.id + "/",
    });

    if (response !== null) {
      setViewTypeDropdown(response.data);
    }
  };

  return (
    <div className='grid grid-cols-3 gap-4  max-h-[100vh]'>
      <div className='border rounded-lg'>
        <div className='bg-[#E2E8F0] p-3'>
          <p className='mb-3 font-[700] text-[18px]' >Lead Name</p>
          <div className='flex justify-between'>
            <div>
              <div className='flex gap-2 mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" className='mt-1' width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.57 22a2 2 0 0 0 1.43-.59l2.71-2.71a1 1 0 0 0 0-1.41l-4-4a1 1 0 0 0-1.41 0l-1.6 1.59a7.55 7.55 0 0 1-3-1.59a7.62 7.62 0 0 1-1.59-3l1.59-1.6a1 1 0 0 0 0-1.41l-4-4a1 1 0 0 0-1.41 0L2.59 6A2 2 0 0 0 2 7.43A15.28 15.28 0 0 0 6.3 17.7A15.28 15.28 0 0 0 16.57 22M6 5.41L8.59 8L7.3 9.29a1 1 0 0 0-.3.91a10.12 10.12 0 0 0 2.3 4.5a10.08 10.08 0 0 0 4.5 2.3a1 1 0 0 0 .91-.27L16 15.41L18.59 18l-2 2a13.28 13.28 0 0 1-8.87-3.71A13.28 13.28 0 0 1 4 7.41zM20 11h2a8.81 8.81 0 0 0-9-9v2a6.77 6.77 0 0 1 7 7" /><path fill="currentColor" d="M13 8c2.1 0 3 .9 3 3h2c0-3.22-1.78-5-5-5z" /></svg>
                <p>{viewTypeDropdown.patient_data?.mobile}</p>
              </div>
              <div className='flex gap-2'>
                <svg xmlns="http://www.w3.org/2000/svg" className='mt-1' width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="10" r="3" /><path d="M12 2a8 8 0 0 0-8 8c0 1.892.402 3.13 1.5 4.5L12 22l6.5-7.5c1.098-1.37 1.5-2.608 1.5-4.5a8 8 0 0 0-8-8" /></g></svg>
                <p>{viewTypeDropdown.patient_data?.branch}</p>
              </div>
            </div>
            <div>
              <p className='mb-3'>Scheduled Date</p>

              <div className='flex gap-2 '>
                <svg xmlns="http://www.w3.org/2000/svg" className='mt-1' width="1em" height="1em" viewBox="0 0 2048 2048"><path fill="currentColor" d="M1792 993q60 41 107 93t81 114t50 131t18 141q0 119-45 224t-124 183t-183 123t-224 46q-91 0-176-27t-156-78t-126-122t-85-157H128V128h256V0h128v128h896V0h128v128h256zM256 256v256h1408V256h-128v128h-128V256H512v128H384V256zm643 1280q-3-31-3-64q0-86 24-167t73-153h-97v-128h128v86q41-51 91-90t108-67t121-42t128-15q100 0 192 33V640H256v896zm573 384q93 0 174-35t142-96t96-142t36-175q0-93-35-174t-96-142t-142-96t-175-36q-93 0-174 35t-142 96t-96 142t-36 175q0 93 35 174t96 142t142 96t175 36m64-512h192v128h-320v-384h128zM384 1024h128v128H384zm256 0h128v128H640zm0-256h128v128H640zm-256 512h128v128H384zm256 0h128v128H640zm384-384H896V768h128zm256 0h-128V768h128zm256 0h-128V768h128z" /></svg>
                <p>{viewTypeDropdown.patient_data?.scheduled}</p>
              </div>
            </div>
          </div>

        </div>
        <div className='p-3 '>
          <div className="sm:my-[5px]">
            <p className="mb-[5px] text-[15px]">
              Name <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Name"
              className="sm:mb-[5px] border border-gray-300 rounded-lg  w-full p-[10px] text-[17px] outline-none"
            value={viewTypeDropdown.patient_data?.name}
            disabled
            />
          </div>
          <div className="sm:my-[5px]">
            <p className="mb-[5px] text-[15px]">
              Mobile No  <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Mobile No "
              className="sm:mb-[5px] border border-gray-300 rounded-lg  w-full p-[10px] text-[17px] outline-none"
              value={viewTypeDropdown.patient_data?.mobile}
              disabled
            />
          </div>
          
          
          <div className="sm:my-[5px]">
            <p className="mb-[5px] text-[15px]">
              Branch <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="branch "
              className="sm:mb-[5px] border border-gray-300 rounded-lg  w-full p-[10px] text-[17px] outline-none"
              value={viewTypeDropdown.patient_data?.branch}
              disabled
            />
          
          </div>
          <div className="sm:my-[5px]">
            <p className="mb-[5px] text-[15px]">
              Source Type <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Source Type"
              className="sm:mb-[5px] border border-gray-300 rounded-lg  w-full p-[10px] text-[17px] outline-none"
              value={viewTypeDropdown.patient_data?.source_types}
              disabled
            />
          </div>
          
          <div className="sm:my-[5px]">
            <p className="mb-[5px] text-[15px]">
              Remarks
            </p>
            <input
              type="text"
              placeholder="Remarks"
              className="sm:mb-[5px] border border-gray-300 rounded-lg  w-full p-[10px] text-[17px] outline-none"
              value={viewTypeDropdown.patient_data?.remarks}
              disabled
            />
          </div>

        </div>
      </div>

      <div className='col-span-2 overflow-scroll max-h-[80vh]'>
        <p className='text-[#0176FF] pl-2'>
          History
        </p>
        {viewTypeDropdown?.list == 0 ? <p className="text-center">No Data</p> :
        <div class="flex items-center  py-10">
          <div class="max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg px-20 ">
            <ol class="relative border-l-4 border-indigo-600 leading-loose -left-[-2.6rem]">
              {viewTypeDropdown?.list?.map((item)=>(
              <li class="mb-10 ml-6 w-[400px]">
                <div
                  class="absolute w-4 h-4 bg-white border-4 border-indigo-600 rounded-full -left-[0.6rem]"
                ></div>
                <p class="absolute -left-[7.5rem] p-0 m-0 font-bold">{item.create_date}</p>
                <p class="font-bold text-lg mb-1">Status Name : {item.status_name}</p>
                
                <p>
                remarks : {item.remarks}
                </p>
              </li>
              ))}
            </ol>
          </div>
        </div>
}
      </div>
    </div>
  )
}

export default Viewreview
