import { useEffect, useState } from "react";
import LogoImage from "../../assets/images/Shiplogologin.svg";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
import { activeUserPermissionEndPoint, logoutEndPoint } from "../../service/api";
import { Link, NavLink, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useGeolocated } from "react-geolocated";
import { useDispatch } from "react-redux";
import { setActions } from "../../redux/commonslice";

export default function SideBar({ isDark, classess, position }) {

    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const [activeMenu, setActiveMenu] = useState('');
    const [activeMenuDropdown, setActiveMenuDropdown] = useState('');

    const [menuList, setMenuList] = useState([])
    console.log("menuList",menuList)

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

        console.log("setActions",setActions)





    useEffect(() => {
        getMenuList()
    }, [])

    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })

        if (response !== null) {
            if (response.status === 200) {
                setMenuList(response.data)
            }
        }
    }
    

    useEffect(() => {
        highlightMenu()
        if(menuList.length > 0) {
            for (var i of menuList) {
                if (i.menu_group_name.toLowerCase() === pathname.toLowerCase().split('/')[1]) {
                    for (var j of i.menu_list) {
                        if (j.menu_path.toLowerCase() === pathname.toLowerCase()) {
                            dispatch(setActions({
                                viewPermit: j.view_permit,
                                addPermit: j.add_permit,
                                editPermit: j.edit_permit,
                                deletePermit: j.delete_permit
                            }))
                        }
                    }
                }
            }
        }

    }, [pathname, menuList])

    const highlightMenu = () => {
        for (var i of menuList) {
            if (i.menu_list !== undefined) {
                for (var j of i.menu_list) {
                    if (j.menu_path === pathname) {
                        setActiveMenuDropdown(i.id)
                        setActiveMenu('menu'+i.id)
                    }
                }
            }
        }
    }



    // const logout = async () => {
    //     if (isGeolocationAvailable) {
    //         if (isGeolocationEnabled) {
    //             var body = {}
    //             body["lattitude"] = coords.latitude
    //             body["longitude"] = coords.longitude
    //             var response = await postAxiosWithToken({
    //                 url: logoutEndPoint,
    //                 body: body
    //             })

    //             if (response !== null) {
    //                 if (response.status === 200) {
    //                     toast.success(response.message)
    //                     localStorage.removeItem('login')
    //                     localStorage.setItem('authenticated', JSON.parse(false))
    //                     window.location.reload()
    //                 } else {
    //                     toast.error(response.message)
    //                 }
    //             }
    //         } else {
    //             toast.error('Enable the location')
    //         }
    //     } else {
    //         toast.error('Enable the location')
    //     }
    // }

    const logout = async () => {
        var response = await postAxiosWithToken({
            url: logoutEndPoint
        })
 
        if (response !== null) {
            if (response.status === 200) {
                toast.success(response.message)
                localStorage.removeItem('login')
                localStorage.setItem('authenticated', JSON.parse(false))
                window.location.reload()
            } else {
                toast.error(response.message)
            }
        }
    }











    return (
        <>
            <div className={`${isDark ? "bg-dark-bg" : "bg-white"} ${classess}`}>
                <div className={`${position === 'left' ? "-right-7" : "-left-7"} absolute top-0 lg:hidden`}
                    onClick={() => {
                        document.getElementById('menubar').className = 'hidden lg:block'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-6 -6 24 24">
                        <path fill="currentColor"
                            d="m7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485L2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535l3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z" />
                    </svg>
                </div>
                <div className="h-[55px] w-[50%] mx-auto mt-[25px] bg-white">
                   <Link to="/dashboard"><img src={LogoImage} alt="no-logo" /></Link> 
                </div>

                <div className={`${isDark ? "bg-dark-bg" : "bg-white"} overflow-y-scroll overflow-x-hidden h-[85vh] my-[25px] py-[10px]`}>
                    {
                        menuList.map((item) => (

                            <div
                                key={item.id}
                                className={`${isDark ? "bg-dark-bg" : "bg-white"} my-3 md:my-0 py-[10px]`}>
                                <div
                                    className={`${activeMenuDropdown === item.id ? isDark ? "text-primary" : "text-black" : ""} font-[500] text-[15px] px-[15px] capitalize font-500  flex items-center gap-[5px]`}
                                > <div className="w-[25px] h-[25px] text-primary"><img width={"100%"} src={item.menu_group_icon} alt="menu-icon" /></div>
                                    <p className="text-[#757575] ">{item.menu_group_name}</p></div>

                                <div
                                 onClick={() => {
                                    document.getElementById('menubar').className = 'hidden md:block' 
                                }}
                                    id={"menu" + item.id}
                                    className={`mt-[10px] w-full`}>
                                    {
                                        item.menu_list.map((subItem) => (
                                            <NavLink 
                                            key={subItem.menu_path} to={subItem.menu_path}
                                            className={({ isActive, isPending }) =>
                                        isActive ? isDark ? "text-white" : "text-white" : ""
                                    }>
                                                <div
                                                onClick={() =>{localStorage.removeItem("status_id");localStorage.removeItem("dasboardbranch");localStorage.removeItem("source_id");localStorage.removeItem("source_displaytypes_id");localStorage.removeItem("entry_type");localStorage.removeItem("review_entry_type");localStorage.removeItem("admission");localStorage.removeItem("type_way")}}
                                                    className={` ${pathname.split('/').includes(subItem.menu_name.toString().toLowerCase().split(' ').join('-')) ? "bg-primary" : ""} py-[7px] relative px-[10px] my-1 cursor-pointer capitalize flex items-center gap-3 rounded-[8px] hover:bg-[#E2E8F0] hover:text-black ml-[30px]`}
                                                    key={subItem.id}
                                                >   
                                                    <div className="w-[18px] h-[18px]"><img width="100%" src={subItem.menu_icon} alt={subItem.menu_name} /></div>
                                                    <p className="text-[15px] ">{subItem.menu_name}</p>
                                                    {/* <div className={`${pathname.split('/').includes(subItem.menu_name.toString().toLowerCase().split(' ').join('-')) ? "block" : "hidden"} w-[5px] h-[25px] bg-white absolute right-[7px] rounded-l-lg`}></div> */}
                                                </div>
                                            </NavLink>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }

                    <div onClick={() => logout()} className="sticky bottom-[0px] bg-[#054EAD]  border-t-2 w-full justify-center  px-[6px] pt-[16px] pb-[20px] flex cursor-pointer ">
                       <svg xmlns="http://www.w3.org/2000/svg" width="50" height="20" color="white" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"><path stroke-dasharray="32" stroke-dashoffset="32" d="M12 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="32;0" /></path><path stroke-dasharray="12" stroke-dashoffset="12" d="M9 12h11.5" opacity="0"><set attributeName="opacity" begin="0.5s" to="1" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.2s" values="12;0" /></path><path stroke-dasharray="6" stroke-dashoffset="6" d="M20.5 12l-3.5 -3.5M20.5 12l-3.5 3.5" opacity="0"><set attributeName="opacity" begin="0.7s" to="1" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="6;0" /></path></g></svg>
                       <p className="text-white">Logout Account</p>
                    </div>

                </div>
                
            </div>
        </>

    );
}