import React, { useState, useEffect } from 'react';
 
const MatchColumns = ({ headerlist, sheetData, setName, setEmail, setMobileno, setLocation, setBranch, setSource,setSelectedOptions ,selectedOptions }) => {
 
  const [validationMessages, setValidationMessages] = useState({ full_name: '', email: '', phone_number: '', city: '', branch: '', source: '' });
 
  
  const handleSelectChange = (column, selectedValue) => {
    setSelectedOptions((prev) => ({ ...prev, [column]: selectedValue }));
  };
  
  const { full_name, email, phone_number, city, branch, source } = selectedOptions; // Declare these variables
  
  useEffect(()=> {
    console.log("selectedOptions", selectedOptions);
  },[selectedOptions])
  useEffect(() => {
    // Set default values based on data keys
    const getDefaultValues = () => {
      const defaultValues = {};
      const dataKeys = Object.keys(sheetData[0] || {});
 
      dataKeys.forEach((key) => {
        defaultValues[key.toLowerCase()] = key; // Use key as the default value
      });
      return defaultValues;
    };
 
    setSelectedOptions(getDefaultValues());
    console.log("getDefaultValues",getDefaultValues())
  }, [sheetData]);
 
useEffect(() => {
  // Validate selected options
  const validateOptions = () => {
    const messages = {};
    Object.keys(selectedOptions).forEach((key) => {
      messages[key] = selectedOptions[key] ? '' : 'Please select a header';
    });
    setValidationMessages(messages);
  };
 
  validateOptions();
}, [selectedOptions]);
 
 
  return (
    <div>
      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
        <table className="w-full">
          <thead className="border bg-primary text-white">
            <tr>
              <td className="p-[10px]">Name</td>
              <td className="p-[10px]">Email</td>
              <td className="p-[10px]">Mobile No</td>
              <td className="p-[10px]">Location</td>
              <td className="p-[10px]">Branch</td>
              <td className="p-[10px]">Source Type</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={full_name}
                  onChange={(e) => {
                    handleSelectChange('full_name', e.target.value);
                    setName(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== email &&
                    key !== phone_number &&
                    key !== city &&
                    key !== branch &&
                    key !== source && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.full_name && <div className="text-red-500">{validationMessages.full_name}</div>}
              </td>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={email}
                  onChange={(e) => {
                    handleSelectChange('email', e.target.value);
                    setEmail(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== phone_number &&
                    key !== city &&
                    key !== branch &&
                    key !== source && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.email && <div className="text-red-500">{validationMessages.email}</div>}
              </td>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={phone_number}
                  onChange={(e) => {
                    handleSelectChange('phone_number', e.target.value);
                    setMobileno(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== email &&
                    key !== city &&
                    key !== branch &&
                    key !== source && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.phone_number && <div className="text-red-500">{validationMessages.phone_number}</div>}
              </td>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={city}
                  onChange={(e) => {
                    handleSelectChange('city', e.target.value);
                    setLocation(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== email &&
                    key !== phone_number &&
                    key !== branch &&
                    key !== source && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.city && <div className="text-red-500">{validationMessages.city}</div>}
              </td>

              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={branch}
                  onChange={(e) => {
                    handleSelectChange('branch', e.target.value);
                    setBranch(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== email &&
                    key !== phone_number &&
                    key !== city &&
                    key !== source && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.branch && <div className="text-red-500">{validationMessages.branch}</div>}
              </td>

              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={source}
                  onChange={(e) => {
                    handleSelectChange('source', e.target.value);
                    setSource(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== email &&
                    key !== phone_number &&
                    key !== city &&
                    key !== branch && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    ) 
                  ))}
                </select>
                {validationMessages.source && <div className="text-red-500">{validationMessages.source}</div>}
              </td>
            </tr>
 
            {sheetData.map((item, index) => (
              <tr key={index}>
                {console.log("items",item,city)}
                <td className="p-[10px]">{full_name ? item[full_name] : `No Data ${full_name}`}</td>
                <td className="p-[10px]">{email ? item[email] : `No Data ${email}`}</td>
                <td className="p-[10px]">{phone_number ? item[phone_number] : `No Data ${phone_number}`}</td>
                <td className="p-[10px]">{city ? item[city] : `No Data ${city}`}</td>
                <td className="p-[10px]">{branch ? item[branch] : `No Data ${branch}`}</td>
                <td className="p-[10px]">{source ? item[source] : `No Data ${source}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
 
export default MatchColumns;