import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getAxios, postAxiosWithToken } from "../../../../service/apiservice";
import { dischargeDetailsListEndPoint, entryDropdownListEndPoint, procedureCreateEndpoint, procedureDropdownListEndPoint } from "../../../../service/api";

export default function Viewdischargedetails({ closeModal,getData }) {
  const [formMode, setFormMode] = useState("update");
  const [entryTypeDropdown, setentryTypeDropdown] = useState([]);
  const [procedureTypeDropdown, setProcedureTypeDropdown] = useState([]);
  const [currentdetails, setCurrentdetails] = useState({});
  const [patientData, setPatientData] = useState({});
  const [entry_type, setEntrytypes] = useState("");

  const navigate = useNavigate();


  const schema = yup
    .object({

      discharge_remarks: yup.string(),
      remarks: yup.string(),
      discharge_date: yup.string(),
      entry_type: yup.string().required(),
      procedure_type: yup.string().required(),
      admission_date: yup.string().required(),
      procedure_date: yup.string().required(),
      procedure_reason: yup.string()
    })
    .required()


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    values:
      formMode === "update"
        ? {
          discharge_remarks: currentdetails?.discharge_remarks,
          remarks: currentdetails?.remarks,
          discharge_date: currentdetails?.discharge_date,
          entry_type: entry_type,
          procedure_type: currentdetails?.procedure_type,
          admission_date: currentdetails?.admission_date,
          procedure_date: currentdetails?.procedure_date,
          procedure_reason: currentdetails?.procedure_reason,
          name: patientData?.name,
          phone: patientData?.phone,
          // source_type: "1",
        }
        : {
          discharge_date: "",
          entry_type: "",
          procedure_type: "",
          admission_date: "",
          procedure_date: "",
          procedure_reason: "",
          discharge_remarks: "",
          remarks: "",
          name: "",
          phone: "",
          // source_type: "",
        },
  });

  useEffect(() => {
    EntryDropdownList();
    ProcedureTypeDropdownList();
    getDischargeDetails();

  }, [])

  const EntryDropdownList = async () => {
    var response = await getAxios({
      url: entryDropdownListEndPoint,
    });

    if (response !== null) {
      setentryTypeDropdown(response.data.list)
    }
  }



  const ProcedureTypeDropdownList = async () => {
    var response = await getAxios({
      url: procedureDropdownListEndPoint,
    });

    if (response !== null) {
      setProcedureTypeDropdown(response.data.list)
    }
  }

  const onSubmit = (data) => {
    console.log("form", formMode);
    console.log(data)
    dischargepatient(data);
    closeModal()
  };

  const dischargepatient = async (body) => {
    var response = await postAxiosWithToken({
      url: dischargeDetailsListEndPoint + localStorage.getItem('dischargeview_form_id') + "/",
      body: body
    })

    if (response != null) {
      getData()
      clearData()
      localStorage.removeItem('dischargeview_form_id')
      toast.success(response.message)
      navigate('/procedure/all-procedure')
    }
  }

  const [selectedEntryType, setSelectedEntryType] = useState('');

  const handleEntryTypeChange = (e) => {
    setSelectedEntryType(e.target.value);
  };

  const getDischargeDetails = async () => {
    var id = localStorage.getItem("dischargeview_form_id");

    var response = await getAxios({
      url: procedureCreateEndpoint + id + "/",
    });

    if (response !== null) {
      setEntrytypes(response.data.procedure_list?.entry_type)
      setCurrentdetails(response.data.procedure_list);
      setSelectedEntryType(response.data.procedure_list?.entry_type,)
      setPatientData(response.data.personal_list);
    }
  };

  const clearData = () => {
    reset()
    setCurrentdetails({
      patient_id: "",
      procedure_reason: "",
      remarks: "",
      procedure_type: "",
      procedure_date: "",
      admission_date: "",
      ip_no: "",
      entry_type: ""
    })
  }

  //today date


  const [defaultDate, setDefaultDate] = useState(getToday());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDefaultDate(getToday());
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(intervalId);
  }, []);

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>{console.log(errors)}
        <div className="w-full h-full p-[25px]">
          <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5 mt-5">
          <div className="my-[5px]">
              <p className="mb-[5px] text-[15px] font-bold">
              Patient Name
              </p>

              <p className="mt-3">{patientData.name}</p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px] font-bold">
                Mobile Number
              </p>

              <p className="mt-3">{patientData.phone}</p>
            </div>
            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px] font-bold">
                Patient Entry Type
              </p>
              <p className="mt-3">{currentdetails.entry_typename}</p>
            </div>
            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px] font-bold">
                Procedure Type
              </p>
              <p className="mt-3">{currentdetails.procedure_name}</p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px] font-bold">
                Admission Date 
              </p>
              <p className="mt-3">{currentdetails.in_date}</p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px] font-bold">
                Procedure Date 
              </p>
              <p className="mt-3">{currentdetails.procedure_date}</p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px] font-bold">
                Procedure Reason
              </p>
              <p>{currentdetails.procedure_reason}</p>
            </div>
          <div className="my-[5px]">
          <p className="mb-[5px] text-[15px] font-bold">
              <label>Remark(admission)</label>
            </p>
            <p>{currentdetails.remarks}</p>
          </div>
          </div>
          <div className='border border-gray-300 mt-[25px]'></div>
          <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5 mt-5">

          <div className="mt-[20px]">
              <p className="mb-[5px] text-[15px] font-bold">
                Discharge Date
              </p>
              <p className="mt-3">{currentdetails.out_date}</p>
          </div>

          <div className="sm:my-[5px] ">
            <p className="mt-5 text-black text-[14px] font-bold">
              <label>Remark(discharge)</label>
            </p>
            <p className="mt-3">{currentdetails.discharge_remarks}</p>
          </div>
          </div>
        </div>
      </form>

    </div>
  );
}
