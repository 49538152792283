import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { branchListEndPoint, cityDropdownEndPoint, countryDropdownEndPoint, departmentListEndPoint, designationListEndPoint, floorListEndPoint, locationListEndPoint, staffEndPoint, stateDropdownEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { phoneRegExp } from "../../../constant";
import { toast } from "react-hot-toast";

export default function StaffForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentStaff, setCurrentStaff] = useState({});

    const [locationDropdown, setLocationDropdown] = useState([]);
    const [branchDropdown, setBranchDropdown] = useState([]);
    const [departmentDropdown, setDepartmentDropdown] = useState([]);
    const [designationDropdown, setDesignationDropdown] = useState([]);
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [statedropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);

    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedState, setSelelectedState] = useState("")
    const [selectedCountry, setSelelectedCountry] = useState("")
    const [selectedCity, setSelelectedCity] = useState("")

    
    const schema = yup
        .object({
            first_name: yup.string(),
            last_name: yup.string(),
            email: yup.string().email('Email is not valid'),
            phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
            state: yup.string(),
            city:yup.string(),
            country: yup.string(),
            address: yup.string(),
            pincode: yup.string(),
            aadhar_card: yup.string(),
            pan_card: yup.string(),
            location: yup.string(),
            branch: yup.string(),
            department: yup.string(),
            designation: yup.string(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            first_name: currentStaff?.first_name,
            last_name: currentStaff?.last_name,
            email: currentStaff?.email,
            phone: currentStaff?.phone,
            state: currentStaff?.state,
            city: currentStaff?.city,
            country: currentStaff?.country,
            address: currentStaff?.address,
            pincode: currentStaff?.pincode,
            aadhar_card: currentStaff?.aadhar_card,
            pan_card: currentStaff?.pan_card,
            location: currentStaff?.location,
            branch: currentStaff?.branch,
            department: currentStaff?.department,
            designation: currentStaff?.designation,
        } : {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            state: "",
            city: "",
            country: "",
            address: "",
            pincode: "",
            aadhar_card: "",
            pan_card: "",
            location: "",
            branch: "",
            department: "",
            designation: "",
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            reset()
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getLocationDropdownData();
        getDepartmentDropdownData();
        getDesignationDropdownData();
        countryDropdownList();

        setFormMode(localStorage.getItem('staff_form_mode'))

        if (localStorage.getItem('staff_form_mode') === 'update') {
            getStaffDetails()
        }
    }, [])

   

    const getStaffDetails = async () => {
        var id =localStorage.getItem('staff_form_id');
        
        var response = await getAxios({
            url: staffEndPoint + id + "/"
        })

        if (response !== null) {
            var tempDetails = {
                first_name: response.data?.first_name,
                last_name: response.data?.last_name,
                email: response.data?.email,
                phone: response.data?.phone,
                state: response.data?.state,
                country: response.data?.country,
                city: response.data?.city,
                address: response.data?.address,
                pincode: response.data?.pincode,
                aadhar_card: response.data?.aadhar_card,
                pan_card: response.data?.pan_card,
                location: response.data?.location,
                branch: response.data?.branch,
                department: response.data?.department,
                designation: response.data?.designation,
            }
            if (response.data.location !== null) {
                getBranchDropdownData(response.data?.location)
            }
            
            stateDropdownList(response.data?.country);
            cityDropdownList(response.data?.state);
            
            
            setSelectedBranch(response.data.branch)
            setSelelectedState(response.data.state)
            setSelelectedCountry(response.data.country)
            setSelelectedCity(response.data.city)

            setCurrentStaff(tempDetails)
        }
    }

    const getLocationDropdownData = async () => {
        var response = await getAxios({
            url: locationListEndPoint
        })

        if (response !== null) {
            setLocationDropdown(response.data.list)
        }
    }

    const getBranchDropdownData = async (id) => {
        var response = await getAxios({
          url: branchListEndPoint + id + "/"
        });
       

        if (response !== null) {
            setBranchDropdown(response.data.list) 
        }
      }

    const getDepartmentDropdownData = async () => {
        var response = await getAxios({
            url: departmentListEndPoint
        })

        if (response !== null) {
            setDepartmentDropdown(response.data.list)
        }
    }

    const getDesignationDropdownData = async () => {
        var response = await getAxios({
            url: designationListEndPoint
        })

        if (response !== null) {
            setDesignationDropdown(response.data.list)
        }
    }

    const stateDropdownList = async (id) => {
        var response = await getAxios({
          url: stateDropdownEndPoint + id + "/"
        });

        if (response !== null) {
            setStateDropdown(response.data.list) 
        }
      }

    const countryDropdownList = async () => {
        var response = await getAxios({
          url: countryDropdownEndPoint 
        });

        if (response !== null) {
            setCountryDropdown(response.data.list) 
        }
      }

      const cityDropdownList = async (id) => {
        console.log("id",id)
        var response = await getAxios({
          url: cityDropdownEndPoint + id + "/"
        });

        console.log("data",response.data.list)

        if (response !== null) {
            setCityDropdown(response.data.list) 
        }
      }

    const onSubmit = async (data) => {
        if (localStorage.getItem('staff_form_mode') === 'create') {
            console.log(data)
            createStaff(data)
        } else {
            updateStaff(data)
        }
    }

    const createStaff = async (body) => {
        var response = await postAxiosWithToken({
            url: staffEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/organization/staff')
        }
    }

    const updateStaff = async (body) => {
        var response = await putAxiosWithToken({
            url: staffEndPoint + localStorage.getItem('staff_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('staff_form_id')
            localStorage.setItem('staff_form_mode', 'create')
            toast.success(response.message)
            navigate('/organization/staff')
        }
    }

  

    const clearData = () => {
        reset()
        setCurrentStaff({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            state: "",
            city: "",
            country: "",
            address: "",
            pincode: "",
            aadhar_card: "",
            pan_card: "",
            location: "",
            branch: "",
            department: "",
            designation: "",
        })
    }



    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Product</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-[15px] rounded-xl shadow-lg">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Staff Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">First Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="first name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="first_name"
                                    id="forminit"
                                    {...register('first_name')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.first_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Last Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="last name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="last_name"
                                    {...register('last_name')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.last_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Email <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email"
                                    {...register('email')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.email?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Phone no <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="phone"
                                    {...register('phone')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.phone?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Location <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="location"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="location"
                                    {...register('location')}
                                    onChange={(e) => getBranchDropdownData(e.target.value)}
                                >
                                    <option value={""}>--select location--</option>
                                    {
                                        locationDropdown.map((option) => (
                                            <option className="capitalize" key={"location" + option.location_name} value={option.id}>{option.location_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.location?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Branch <span className="text-red-500">*</span></p>
                                <select
                            placeholder="Branch"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="branch"
                            {...register('branch')}
                            value={selectedBranch}
                            onChange={(e) => setSelectedBranch(e.target.value)}
                        >
                            <option value={""}>--select branch--</option>
                            {
                                branchDropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.branch_name}</option>
                                ))
                            }
                        </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.branch?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Department <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="department"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="department"
                                    {...register('department')}
                                >
                                    <option value={""}>--select department--</option>
                                    {
                                        departmentDropdown.map((option) => (
                                            <option className="capitalize" key={"department" + option.department_name} value={option.id}>{option.department_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.department?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Designation <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="designation"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="designation"
                                    {...register('designation')}
                                >
                                    <option value={""}>--select designation--</option>
                                    {
                                        designationDropdown.map((option) => (
                                            <option className="capitalize" key={"designation" + option.designation_name} value={option.id}>{option.designation_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.designation?.message}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]">
                        <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">Address Details</p>
                        <div className="grid xl:grid-cols-1 sm:grid-cols-1 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Address <span className="text-red-500">*</span></p>
                                <textarea 
                                    rows="4" cols="80"
                                    placeholder="address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg  p-[10px] text-[17px] outline-none"
                                    name="address"
                                    {...register('address')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.address?.message}</p>
                            </div>

                            <div className="flex gap-[20px]">

                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Country <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-[200px] p-[11px] text-[17px] outline-none"
                                    name="country"
                                    {...register('country')}
                                    value={selectedCountry}
                                    onChange={(e) => {setSelelectedCountry(JSON.parse(e.target.value)); stateDropdownList(e.target.value)}}
                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.country_name} value={option.id}>{option.country_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.country?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-[200px] p-[11px] text-[17px] outline-none"
                                    name="state"
                                    {...register('state')}
                                    value={selectedState}
                                    onChange={(e) => {setSelelectedState(JSON.parse(e.target.value)); cityDropdownList(e.target.value)}}
                                > 


                                    <option value={""}>--select state--</option>
                                    {
                                statedropdown.map((option) => (
                                    <option key={option.state_name} value={option.id} className="capitalize">{option.state_name}</option>

                                ))
                            }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.state?.message}</p>
                            </div>

                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">City <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-[200px] p-[11px] text-[17px] outline-none"
                                    name="city"
                                    {...register('city')}
                                    value={selectedCity}
                                    onChange={(e) => {setSelelectedCity(e.target.value)}}
                                > 

                                    <option value={""}>--select city--</option>
                                    {
                                cityDropdown.map((option) => (
                                    <option key={option.city_name} value={option.id} className="capitalize">{option.city_name}</option>

                                ))
                            }

                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.state?.message}</p>
                            </div>
                            
                            <div className="sm:my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg  w-[200px] p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    {...register('pincode')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.pincode?.message}</p>
                            </div>

                            </div>
                        </div>
                    </div>

                   

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
                        <button
                            id="backnav"
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                            >close</button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save</button>
                    </div>
                </form>
            </div>
        </>
    );
}