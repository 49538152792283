import React, { useState, useEffect } from 'react';
 
const DropOutMatchColumns = ({ headerlist, sheetData, setName, setEmail, setMobileno, setLocation, setSource,setSelectedOptions ,selectedOptions,setAlternativeno,setHistory,setProceduretype,setRemarks1,setRemarks2 }) => {
 
  const [validationMessages, setValidationMessages] = useState({  full_name: '', mobile: '', alternate_number: '',  source_type: '', history: '',procedure:'',remarks1: '',remarks2: '' });
 
  console.log("selectedOptions", selectedOptions);
 
  const handleSelectChange = (column, selectedValue) => {
    setSelectedOptions((prev) => ({ ...prev, [column]: selectedValue }));
  };
 
  const { full_name, mobile, alternate_number, source_type,history, procedure,remarks1,remarks2 } = selectedOptions; // Declare these variables
 
  useEffect(() => {
    // Set default values based on data keys
    const getDefaultValues = () => {
      const defaultValues = {};
      const dataKeys = Object.keys(sheetData[0] || {});
 
      dataKeys.forEach((key) => {
        defaultValues[key] = key; // Use key as the default value
      });
      return defaultValues;
    };
 
    setSelectedOptions(getDefaultValues());
  }, [sheetData]);
 
useEffect(() => {
  // Validate selected options
  const validateOptions = () => {
    const messages = {};
    Object.keys(selectedOptions).forEach((key) => {
      messages[key] = selectedOptions[key] ? '' : 'Please select a header';
    });
    setValidationMessages(messages);
  };
 
  validateOptions();
}, [selectedOptions]);
 
 
  return (
    <div>
      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
        <table className="w-full">
          <thead className="border bg-primary text-white">
            <tr>
              <td className="p-[10px]">Name</td>
              <td className="p-[10px]">Mobile No</td>
              <td className="p-[10px]">Alternative No</td>
              <td className="p-[10px]">source_type Type</td>
              <td className="p-[10px]">History</td>
              <td className="p-[10px]">Procedure Status</td>
              <td className="p-[10px]">Remarks 1</td>
              <td className="p-[10px]">Remarks 2</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={full_name}
                  onChange={(e) => {
                    handleSelectChange('full_name', e.target.value);
                    setName(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== mobile &&
                    key !== alternate_number &&
                    key !== history &&
                    key !== procedure &&
                    key !== remarks1 &&
                    key !== remarks2 && 
                    key !== source_type &&(
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.full_name && <div className="text-red-500">{validationMessages.full_name}</div>}
              </td>
              
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={mobile}
                  onChange={(e) => {
                    handleSelectChange('mobile', e.target.value);
                    setMobileno(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== alternate_number &&
                    key !== history &&
                    key !== procedure &&
                    key !== remarks1 &&
                    key !== remarks2 && 
                    key !== source_type &&(
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.mobile && <div className="text-red-500">{validationMessages.mobile}</div>}
              </td>

              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={alternate_number}
                  onChange={(e) => {
                    handleSelectChange('alternate_number', e.target.value);
                    setAlternativeno(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== mobile &&
                    key !== history &&
                    key !== procedure &&
                    key !== remarks1 &&
                    key !== remarks2 &&
                    key !== source_type && (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.alternate_number && <div className="text-red-500">{validationMessages.alternate_number}</div>}
              </td>

              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={source_type}
                  onChange={(e) => {
                    handleSelectChange('source_type', e.target.value);
                    setSource(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== mobile &&
                    key !== alternate_number &&
                    key !== history &&
                    key !== procedure &&
                    key !== remarks1 &&
                    key !== remarks2 &&(
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.source_type && <div className="text-red-500">{validationMessages.source_type}</div>}
              </td>

              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={history}
                  onChange={(e) => {
                    handleSelectChange('history', e.target.value);
                    setHistory(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== mobile &&
                    key !== alternate_number &&
                    key !== procedure &&
                    key !== remarks1 &&
                    key !== remarks2 &&
                    key !== source_type &&(
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.history && <div className="text-red-500">{validationMessages.history}</div>}
              </td>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={procedure}
                  onChange={(e) => {
                    handleSelectChange('procedure', e.target.value);
                    setProceduretype(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== mobile &&
                    key !== alternate_number &&
                    key !== history &&
                    key !== remarks1 &&
                    key !== remarks2 &&
                    key !== source_type &&(
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.procedure && <div className="text-red-500">{validationMessages.procedure}</div>}
              </td>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={remarks1}
                  onChange={(e) => {
                    handleSelectChange('remarks1', e.target.value);
                    setRemarks1(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== mobile &&
                    key !== alternate_number &&
                    key !== history &&
                    key !== procedure &&
                    key !== remarks2 &&
                    key !== source_type &&(
                      <option value={key} key={key}>
                        {key}
                      </option>
                    )
                  ))}
                </select>
                {validationMessages.remarks1 && <div className="text-red-500">{validationMessages.remarks1}</div>}
              </td>
              <td className="p-[10px] min-w-[200px]">
                <select
                  className="w-full border outline-none rounded-md py-[5px] px-[10px]"
                  value={remarks2}
                  onChange={(e) => {
                    handleSelectChange('remarks2', e.target.value);
                    setRemarks2(e.target.value);
                  }}
                >
                  <option value={''}>select header</option>
                  {headerlist?.map((key) => (
                    key !== full_name &&
                    key !== mobile &&
                    key !== alternate_number &&
                    key !== history &&
                    key !== procedure &&
                    key !== remarks1 &&
                    key !== source_type &&(
                        <option value={key} key={key}>
                          {key}
                        </option>
                      )
                  ))}
                </select>
                {validationMessages.remarks2 && <div className="text-red-500">{validationMessages.remarks2}</div>}
              </td>
            </tr>
 
            {sheetData.map((item, index) => (
              <tr key={index}>
                <td className="p-[10px]">{full_name ? item[full_name] : `No Data ${full_name}`}</td>
                <td className="p-[10px]">{mobile ? item[mobile] : `No Data ${mobile}`}</td>
                <td className="p-[10px]">{alternate_number ? item[alternate_number] : `No Data ${alternate_number}`}</td>
                <td className="p-[10px]">{source_type ? item[source_type] : `No Data ${source_type}`}</td>
                <td className="p-[10px]">{history ? item[history] : `No Data ${history}`}</td>
                <td className="p-[10px]">{procedure ? item[procedure] : `No Data ${procedure}`}</td>
                <td className="p-[10px]">{remarks1 ? item[remarks1] : `No Data ${remarks1}`}</td>
                <td className="p-[10px]">{remarks2 ? item[remarks2] : `No Data ${remarks2}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
 
export default DropOutMatchColumns;