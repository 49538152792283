import React, { useEffect, useState } from "react";
import { DeleteButton, EditButton } from "../../../components/Actions";
import { convertToTime } from "../../../utilities/time";
import { convertToDate } from "../../../utilities/datetime";
import SwitchButton from "../../../components/Switch";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  allIncommingTableDataEndPoint,
  allPatientTableDataEndPoint,
  branchListEndPoint,
  departmentEndPoint,
  departmentListEndPoint,
  departmentStatusEndPoint,
  patientEndPoint,
  sorceTypeListEndPoint,
} from "../../../service/api";
import Searchbar from "../../../components/SearchBar";
import Pagination from "../../../components/Pagination";
import CustomePagination from "../../../components/CustomPagination";
import Popup from "../../../components/Popup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DatePicker1 from "../../../components/DatePicker1";
import Addincomingform from "./addincomingform";
import Modal from "../../../components/Model";

function Incomingappointment() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [allincomingTableList, setAllincomingTableList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [formMode, setFormMode] = useState("create");
  const [currentPatient, setCurrentPatient] = useState({});
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [isIncommingOpen, setIsIncommingOpen] = useState(false);
  const [totalitems, setTotalitems] = useState({});


  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const [body, setBody] = useState({
    search: "",
    from_date: "",
    to_date: "",
    page: 1,
    branch: localStorage.getItem("branch"),
    items_per_page: 10,
  });




  function closeIncommingModal() {
    setIsIncommingOpen(false);
  }






  useEffect(() => {
    getAllLeadsListData();
    getBranchDropdownData();
  }, [body]);

  const getAllLeadsListData = async () => {
    var response = await postAxiosWithToken({
      url: allIncommingTableDataEndPoint,
      body: body,
    });

    if (response !== null) {
      setAllincomingTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };

  // const statusHandler = (e) => {
  //   setBody((prevState) => ({
  //     ...prevState,
  //     active_status: e.target.value === "" ? "" : JSON.parse(e.target.value),
  //     page: 1,
  //   }));
  // };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: e.target.value,
      page: 1,
    }));
  };





  const handleDateFilter = async (e) => {
    console.log(e);
    if (e.startDate === null && e.endDate === null ){
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    }else{
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }
    
    setValue(e)
  };

  // const deletepatient = async () => {
  //   var response = await deleteAxiosWithToken({
  //     url: patientEndPoint + currentPatient.id + "/",
  //   });

  //   if (response !== null) {
  //     getAllLeadsListData();
  //     closeDeleteModal();
  //     toast.success(response.message);
  //   }
  // };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };


 





  return (
    <div>
      <div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[650px]:block max-[650px]:mb-[50px] ">
        <p className=" sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
          InComing
        </p>

        <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[650px]:block max-[650px]:mb-[50px] ">
      
        <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">
          <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              <Searchbar value={body.search} handleChange={searchHandler} />
          </div>

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

          <div className="flex gap-[10px] max-[520px]:mt-5 ">
          <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="branch"
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
           
            <div className="text-end">
              <button
                
                onClick={() => { localStorage.setItem('incomming_form_mode', 'create'); setIsIncommingOpen(true); }}
                className="w-[200px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
              >
                <span className="flex justify-center items-center gap-[10px] ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16 19H22M19 16V22M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  Add New
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div className="h-[40px] w-[300px] max-[425px]:mt-[5px] max-[425px]:w-full ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px] w-[1000px]">S.No</td>
              <td className="p-[10px] w-[1000px]">Name</td>
              <td className="p-[10px] w-[1000px]">Mobile No</td>
              <td className="p-[10px] w-[1000px]">Appointment Date</td>
              <td className="p-[10px] w-[1000px]">Source Type</td>
              <td className="p-[10px] w-[1000px]">Branch</td>
              <td className="p-[10px] w-[1000px]">Location</td>
              <td className="p-[10px] w-[1000px]">Create Date</td>
              <td className="p-[10px] w-[1000px]">Created By</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={7}>
                  Loading...
                </td>
              </tr>
            ) : allincomingTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={7}>
                  No Data
                </td>
              </tr>
            ) : (
              allincomingTableList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px] capitalize">{item.sno}</td>
                  <td className="p-[10px] capitalize">{item.name}</td>
                  <td className="p-[10px] capitalize">{item.mobile}</td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.appointment_date)}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.appointment_date)}
                    </p>
                  </td>

                  <td className="p-[10px] w-[1000px] capitalize">{item.source_name}</td>
                  <td className="p-[10px] capitalize">{item.branch_name}</td>
                  <td className="p-[10px] capitalize">{item.location_name}</td>

                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.created_at)}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.created_at)}
                    </p>
                  </td>
                  <td className="p-[10px] capitalize">{item.created_by_name}</td>

                  <td className="p-[10px]">
                    <div className="flex gap-3">
                      {localStorage.getItem("branch") != 0 ? (
                        <div
                          onClick={() => {
                            setCurrentPatient(item);
                            localStorage.setItem("patient_form_mode", "update");
                            localStorage.setItem(
                              "patient_form_id",
                              item.id.toString()
                            );
                            navigate("/all-patient/create-patient");
                          }}
                          className="cursor-pointer hover:scale-125"
                        >
                          <EditButton />
                        </div>
                      ) : (
                        ""
                      )}
                      
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>


      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entrieshandler={entriesHandler}
          totalitmes={totalitems.total_items}
        />
      </div>

      <Popup  
       title={"Create InComming Appointment"}
       setIsOpen={setIsIncommingOpen}
       isOpen={isIncommingOpen}
       closeModal={closeIncommingModal}
      >
                 <Addincomingform onClose={closeIncommingModal} getAllLeadsListData={getAllLeadsListData} />
                </Popup>
    
    </div>
  );
}

export default Incomingappointment;
