import React, { useEffect, useState } from "react";
import { importBranchListEndPoint, leadSourceDropdownEndPoint } from "../../service/api";
import { getAxios } from "../../service/apiservice";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useForm } from "react-hook-form";



export default function ValidateData({ jsonlist,setJsonData, formData, setFormData}) {

console.log("jsonlist",jsonlist)

  const [branchDropdown, setBranchDropdown] = useState([]);
  const[sourcedorpdown ,setSourceDropdown ]=useState([]);
    const [errors, setErrors] = useState({});



  useEffect(() => {
    getSourceDropdownData();
    getBranchDropdownData();
  }, []);

  const getSourceDropdownData = async () => {
    var response = await getAxios({
      url: leadSourceDropdownEndPoint,
    });

    if (response !== null) {
      setSourceDropdown(response.data.list);
      
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: importBranchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };



  const handleXLsheet = (value,index) => {
    console.log("source",value)
    const data= [...jsonlist]
    data[index]['source_type'] = value
    setJsonData(data)
  }

  const handlebranchXlSheet =(e,index) => {
    const { name, value } = e.target;
    const data= [...jsonlist]
    data[index]['Branch'] = value
    
    if (value.length > 0) {
      data[index]['branch_error'] = ""
    } else {
      data[index]['branch_error'] = name + "is required"
    }
    
    setJsonData(data)
  }



  return (
    <div>
      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
        <table className="w-full">
          <thead className="border bg-primary text-white">
            <tr>
              <td className="p-[10px]">Name</td>
              <td className="p-[10px]">Email</td>
              <td className="p-[10px]">Mobile No</td>
              <td className="p-[10px]">Location</td>
              <td className="p-[10px]">Branch</td>
              <td className="p-[10px]">Source Type</td>
            </tr>
          </thead>
          <tbody>
           
                   
                   
            
            
               {jsonlist?.map((item,index) =>
                  <tr >
                   
                 <td className="p-[10px]">{ item.name}</td>
                 <td className="p-[10px]">{ item.email}</td>
                 <td className="p-[10px]">{ item.mobile}</td>
                 <td className="p-[10px]">{ item.location}</td>
                 <td className="p-[10px]">
                 <select
                 onChange={(e) => handlebranchXlSheet(e,index)}
                 name="Branch"
                 id="Branch"
                  value={item.Branch}
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
            >
                <option value={""}>--select branch--</option>
              {branchDropdown.map((option) => (
                <option
                  className="capitalize"
                  key={"branch_name" + option.branch_name}
                  value={option.id}
                >
                  {option.branch_name}
                </option>
              ))}

              </select>
              <p className="text-red-500 font-[400] text-[8px]">{item.branch_error}</p>

                 </td>


                 <td className="p-[10px]">
                  <select
                  onChange={(e)=> handleXLsheet(e.target.value,index)}
                  value={item.source_type}
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
            >
                <option value={""}>{item.source}</option>
              {sourcedorpdown.map((option) => (
                <option
                  className="capitalize"
                  key={"source_name" + option.source_name}
                  value={option.id}
                >
                  {option.source_name}
                </option>
              ))}

              </select>
                  </td>
                 </tr>
                 )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
