import React, { useEffect, useState } from 'react'
import { getAxios, postAxiosWithToken } from '../../service/apiservice';
import { CancerLeadListDataEndPoint, LeadTypeDataEndPoint, allLeadsTableDataEndPoint, branchListEndPoint, importBranchListEndPoint, statusTypeListEndPoint } from '../../service/api';
import { convertToDate } from '../../utilities/datetime';
import { convertToTime } from '../../utilities/time';
import CustomePagination from '../../components/CustomPagination';

import { useNavigate } from 'react-router-dom';
import DatePicker1 from '../../components/DatePicker1';
import Searchbar from '../../components/SearchBar';

const CancerLead = () => {

  const [cancerleadsTableList, setCancerleadsTableList] = useState([]);
  // const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentstatus, setCurrentstatus] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [statusTypeDropdown, setStatusTypeDropdown] = useState([]);
  const [LeadsDropdown, setLeadsDropdown] = useState([]);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isIncommingOpen, setIsIncommingOpen] = useState(false);
  const [totalitems, setTotalitems] = useState({});


  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [body, setBody] = useState({
    from_date: "",
    to_date : "",
    // branch: Number(localStorage.getItem('branch')),
    search: "",
    page : 1,
    items_per_page : 10,
  });

  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function closeIncommingModal() {
    setIsIncommingOpen(false);
  }

  
  const navigate = useNavigate();

  useEffect(() => {
    getCancerLeadsListData();
  }, [body]);


  const getCancerLeadsListData = async () => {
    var response = await postAxiosWithToken({
      url: CancerLeadListDataEndPoint,
      body: body,
    });

    if (response !== null) {
      setCancerleadsTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };
  useEffect(()=>{
    getBranchDropdownData();
    getStatusDropdownData();
    getLeadsDropdownData();

  },[])

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: importBranchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };


  const getLeadsDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadsDropdown(response.data.list);
    }
  };




  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: statusTypeListEndPoint,
    });

    if (response !== null) {
      setStatusTypeDropdown(response.data.list);
    }
  };



    // Filter
    const handleDateFilter = async (e) => {
      if (e.startDate === null && e.endDate === null) {
        setBody((prevState) => ({
          ...prevState,
          from_date: "",
          to_date: "",
          page: 1,
        }));
      } else {
        setBody((prevState) => ({
          ...prevState,
          from_date: e.startDate,
          to_date: e.endDate,
          page: 1,
        }));
      }
  
      setValue(e);
    };
  

    const searchHandler = (e) => {
      setBody((prevState) => ({
        ...prevState,
        search: e.target.value,
        page: 1,
      }));
    };
  
    const pageHandler = (value) => {
      setBody((prevState) => ({
        ...prevState,
        page: value,
      }));
    };
  
    const entriesHandler = (value) => {
      setBody((prevState) => ({
        ...prevState,
        items_per_page: value,
        page: 1,
      }));
    };


  return (
    <div>

<div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">

<p className=" sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
          Cancer Leads
        </p>

          <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">


            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full">
              <Searchbar value={body.search} handleChange={searchHandler}  />
            </div>

        
        </div>
          </div>

        


        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
          <table className="w-full">
            <thead className="border bg-secondary text-white">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[200px]">Name</td>
                <td className="p-[10px] min-w-[150px]">Mobile No</td>
                <td className="p-[10px] min-w-[200px]">Email</td>
                <td className="p-[10px] min-w-[200px]">Appointment Date</td>
                <td className="p-[10px] min-w-[200px]">Appointment Time</td>
                <td className="p-[10px] min-w-[200px]">Create Date</td>
                
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={6}>
                    Loading...
                  </td>
                </tr>
              ) : cancerleadsTableList.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={6}>
                    No Data
                  </td>
                </tr>
              ) : (
                cancerleadsTableList.map((item, index) => (
                  <tr key={item.id} className="border">
                    <td className="p-[10px]">{item.sno}</td>

                    <td className="p-[10px]">{item.name}</td>

                    <td className="p-[10px]">{item.mobile}</td>
                    <td className="p-[10px]">{item.email}</td>
                   
                    <td className="p-[10px] capitalize">{item.appointment_date}</td>

                    <td className="p-[10px] capitalize">{item.appointment_time}</td>
                    <td className="p-[10px] capitalize">{item.create_date}</td>
                    
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}
          />

      </div>
     

        
      
    </div>
  )
}

export default CancerLead;
