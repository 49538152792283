import React, { useEffect, useState } from "react";
import { leadSourceDropdownEndPoint } from "../../service/api";
import { getAxios } from "../../service/apiservice";
import toast from "react-hot-toast";

export default function DropOutValidateData({ jsonlist,setJsonData, formData, setFormData}) {


console.log("jsonlist",jsonlist)

  const[sourcedorpdown ,setSourceDropdown ]=useState([]);

  useEffect(() => {
    getSourceDropdownData()
  }, []);

  const getSourceDropdownData = async () => {
    var response = await getAxios({
      url: leadSourceDropdownEndPoint,
    });

    if (response !== null) {
      setSourceDropdown(response.data.list);
      
    }
  };



  const handleXLsheet = (value,index) => {

    const data= [...jsonlist]

    data[index]['source_type'] = value
    setJsonData(data)

  }



  return (
    <div>
      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
        <table className="w-full">
          <thead className="border bg-primary text-white">
            <tr>
              <td className="p-[10px]">Name</td>
              <td className="p-[10px]">Mobile No</td>
              <td className="p-[10px]">Alternative No</td>
              <td className="p-[10px]">Source Type</td>
              <td className="p-[10px]">History</td>
              <td className="p-[10px]">Procedure</td>
              <td className="p-[10px]">Reamrks1</td>
              <td className="p-[10px]">Reamrks2</td>
            </tr>
          </thead>
          <tbody>
           
                   
                   
            
            
               {jsonlist?.map((item,index) =>
                  <tr >
                   
                 <td className="p-[10px]">{ item.name}</td>
                 <td className="p-[10px]">{ item.mobile}</td>
                 <td className="p-[10px]">{ item.alternate_number}</td>
                 <td className="p-[10px]">
                  <select
                  onChange={(e)=> handleXLsheet(e.target.value,index)}
                  

                  value={item.source_type}
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
            >
                <option value={""}>{item.source}</option>
              {sourcedorpdown.map((option) => (
                <option
                  className="capitalize"
                  key={"source_name" + option.source_name}
                  value={option.id}
                >
                  {option.source_name}
                </option>
              ))}

              </select>
                  </td>
                  <td className="p-[10px]">{ item.history}</td>
                  <td className="p-[10px]">{ item.procedure}</td>
                  <td className="p-[10px]">{ item.remarks1}</td>
                  <td className="p-[10px]">{ item.remarks2}</td>
                 </tr>
                 )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
