import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAxios, postAxios, postAxiosWithToken } from '../../../../service/apiservice';
import { branchListEndPoint, incommingEndPoint, patientMobilenumberEndpoint, sorceTypeListEndPoint, sorceTypeotherListEndPoint } from '../../../../service/api';
import toast from 'react-hot-toast';
import { phoneRegExp } from '../../../../constant';
import { Navigate, useNavigate } from 'react-router-dom';

const Addincomingform = ({ onClose, getAllLeadsListData }) => {
  const [formMode, setFormMode] = useState('create');
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [sorceDropdown, setSorceDropdown] = useState([]);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [currentPatient, setCurrentPatient] = useState({});
  const [errors, setErrors] = useState(null);
  const [response, setResponse] = useState({});

  console.log("response",response);



  console.log("currentPatient",currentPatient)

  const mobileNumbers = {
    mobile: mobileNumber
  };



  useEffect(() => {
    getBranchDropdownData();
    getSorceTypeDropdownData();
  }, [])


  useEffect(() => {
    handleSearch()
  }, [mobileNumber])


  const navigate = useNavigate();


  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    if (name == "source_type") {
      const selectedLabel = e.target.options[e.target.selectedIndex].label;
      setSelectedOptionLabel(selectedLabel);
    }
    if (name == "mobile") {
      setMobileNumber(e.target.value);
      handleSearch();
    }

    setCurrentPatient((prevState) => ({
      ...prevState,
      [name]: value,

    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };

  // const onSubmit = async (data) => {
  //   if (localStorage.getItem('incomming_form_mode') === 'create') {
  //     data['branch'] = document.getElementById('branch').value
  //     // createallIncommingLeads(data)
  //   } else {

  //     // updateStaff(data)
  //   }
  // }




  const isValidForm = (formDataObject) => {
    const err = {};
    Object.entries(formDataObject).forEach(([field, value]) => {
      if (value.length === 0) {
        err[field] = `${field} field is required`;
      } else {
        err[field] = "";
      }
    });

    // Validate mobile number field
    const mobileRegex = /^[0-9]{10}$/; // Assumes a 10-digit mobile number
    if (!mobileRegex.test(formDataObject.mobile)) {
      err['mobile'] = 'Invalid mobile number';
    } else {
      err['mobile'] = '';
    }

    // Additional validation for mobile number length
    if (formDataObject.mobile.length > 10) {
      err['mobile'] = 'Mobile number should be 10 digits';
    }


    err['appointment_date'] = '';
    err['additional_mobile'] = '';
    err['remarks'] = '';
    err['email'] = '';

    setErrors((prevState) => ({
      ...prevState,
      ...err,
    }));

    const hasErrors = Object.values(err).some((error) => error.length > 0);

    return !hasErrors;
  };



  const onSubmit = (e) => {
    e.preventDefault();

    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());

    if (isValidForm(formDataObject)) {
      console.log("Form data is valid:", formDataObject);

      if (localStorage.getItem("incomming_form_mode") === "create") {
        currentPatient['branch'] = document.getElementById('branch').value
        createallIncommingLeads(currentPatient);

      }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };

  const createallIncommingLeads = async (body) => {
    var response = await postAxiosWithToken({
      url: incommingEndPoint,
      body: body
    })

    if (response != null) {
      toast.success(response.message)
      onClose();
      // getAllLeadsListData();
      navigate("/leads/confirmed")
    }
  }




  const handleSearch = async () => {
    let mobile_no = mobileNumbers?.mobile?.length; // Get the length of the mobile number

    if (mobile_no === 10) {  // Checking if mobile_no exists and its length is 10
      var response = await postAxiosWithToken({
        url: patientMobilenumberEndpoint,
        body: mobileNumbers,
        showToast: false
      });

      if (response != null) {
        toast.success(response.message);
        setResponse(response)
        var tempDetails = {
          name: response.data?.name,
          additional_mobile: response.data?.additional_mobile,
          mobile: response.data?.mobile,
          marriage_since: response.data?.marriage_since,
          email: response.data?.email,
          source_type: response.data?.source_type,
          remarks: response.data?.remarks,
          branch: response.data?.branch,
          location: response.data?.location,
          appointment_date: response.data?.appointment_date,
          camp_name: response.data?.camp_name,
        }
        setCurrentPatient(tempDetails);

      }
    }
  };





  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };
  const getSorceTypeDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeotherListEndPoint,
    });

    if (response !== null) {
      setSorceDropdown(response.data.list);
    }
  };


  return (
    <div>


      <form onSubmit={onSubmit}>
        <div className="w-full h-full pb-[30px] pt-[20px] px-[30px]">


          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Name <span className="text-red-500">*</span></p>

              <input
                type="text"
                name="name"
                placeholder="Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.name}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.name}</p>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">E-mail  </p>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.email}
                onChange={onValueUpdate}
              />
              {/* <p style={{ color: 'red' }}>{errors?.email}</p> */}

            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Mobile Number  <span className="text-red-500">*</span></p>
              <input
                type="text"
                name="mobile"
                id="mobile"
                placeholder=" Mobile Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.mobile}
                onChange={onValueUpdate}
              //   onChange={(e) => {
              //     setMobileNumber(e.target.value);
              //     handleSearch();
              //     onValueUpdate(e.target);
              // }}
              />
              <p style={{ color: 'red' }}>{errors?.mobile}</p>
            </div>


            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Additional Mobile Number </p>
              <input
                type="text"
                name="additional_mobile"
                id="additional_mobile"
                placeholder="Additional Mobile Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.additional_mobile}
                onChange={onValueUpdate}
              />
            </div>



          </div>

          <div className="flex gap-[10px] mt-4">


            {localStorage.getItem("branch") !== "0" ?
              <div className="w-1/2 ">
                <p className="mb-[5px] text-[15px]">Branch  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Branch"
                  id="branch"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={localStorage.getItem('branch')}
                  disabled
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option key={option.id} value={option.id} className="capitalize">
                      {option.branch_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: 'red' }}>{errors?.branch}</p>
              </div>
              :



              <div className="w-1/2 ">
                <p className="mb-[5px] text-[15px]">Branch  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Branch"
                  id="branch"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="branch"
                  defaultValue={localStorage.getItem("branchid") || ""}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option key={option.id} value={option.id} className="capitalize">
                      {option.branch_name}
                    </option>
                  ))}
                </select>  <p style={{ color: 'red' }}>{errors?.branch}</p>
              </div>
            }





            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">location  <span className="text-red-500">*</span></p>
              <input
                type="text"
                name="location"
                id="location"
                placeholder="Location"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.location}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.location}</p>
            </div>




          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Marriage Since  <span className="text-red-500">*</span></p>
              <input
                type="date"
                name="marriage_since"
                id="marriage_since"
                placeholder=" Marriage Since"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.marriage_since}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.marriage_since}</p>
            </div>
            <div className="w-1/2">
              <p className="text-black text-[15px] font-500 mt-1"> Schedule Date  </p>

              <div className="flex gap-[10px] items-center">
                <input
                  type="date"
                  name="appointment_date"
                  id='appointment_date'
                  className="sm:mb-[5px] h-[45px] w-full border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                  onChange={onValueUpdate}
                  value={currentPatient.appointment_date}
                />
              </div>
            </div>





          </div>




          <div className='flex gap-[10px] mt-4'>
            {response.status === 200 ?
              <div className="w-1/2">
                <p className="mb-[5px] text-[15px]">Source Type  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Source Type"
                  id="source_type"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="source_type"
                  // onClick={(e)=> setcampName(e.target.value)}
                  value={currentPatient.source_type}
                  onChange={onValueUpdate}
                  disabled
                >
                  <option value={""}>--select source--</option>
                  {sorceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: 'red' }}>{errors?.source_type}</p>
              </div> : 
              <div className="w-1/2">
                <p className="mb-[5px] text-[15px]">Source Type  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Source Type"
                  id="source_type"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="source_type"
                  // onClick={(e)=> setcampName(e.target.value)}
                  value={currentPatient.source_type}
                  onChange={onValueUpdate}
                >
                  <option value={""}>--select source--</option>
                  {sorceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: 'red' }}>{errors?.source_type}</p>
              </div>
            }

            {
              selectedOptionLabel.split(" ").join("-").toLowerCase() === "camp-other" && "camp-digital" ?

                <div className='w-1/2 '>
                  <p className="mb-[5px] text-[15px]">Camp Name  <span className="text-red-500">*</span></p>
                  <input
                    type="text"
                    name="camp_name"
                    id="camp_name"
                    placeholder="Camp Name"
                    className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    value={currentPatient.camp_name}
                    onChange={onValueUpdate}
                  />
                  <p style={{ color: 'red' }}>{errors?.camp_name}</p>
                </div>

                : ""
            }


          </div>
          <div>
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remarks</label>
            </p>
            <textarea
              className="mt-5 border-2 w-full border-black-500 p-1"
              id="remarks"
              name="remarks"
              rows="4"
              cols="50"
              onChange={onValueUpdate}
              value={currentPatient.remarks}
            ></textarea>
          </div>
          <div className="flex gap-[10px]">
            <button

              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              SAVE
            </button>
            <div
              onClick={onClose}
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              CLOSE
            </div>
          </div>
        </div>
      </form>

    </div>
  )
}

export default Addincomingform;
