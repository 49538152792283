import React, { useEffect, useState } from "react";
import {
  branchListEndPoint,
  getALLDatabyIdEndPoint,
  getUpdateleadEndPoint,
  leadsPopUpStatusbasedEndPoint,
  sorceTypeotherListEndPoint,
  DropoutStatusUpdateEndPoint,
  statusDropdownEndPoint,
  DropoutStatusGetDataEndPoint
} from "../../../../service/api";
import { getAxios, putAxiosWithToken } from "../../../../service/apiservice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";

export default function DropOutDropDown({ onClose, currentstatus, getData }) {

  console.log("currentstatus",currentstatus)


  const [statusTypeDropdown, setStatusTypeDropdown] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [statusData, setStatusData] = useState({});
  const [formMode, setFormMode] = useState("update");
  const [isChecked, setIsChecked] = useState(0);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const [tobranchDropdown, setTobranchDropdown] = useState(0);
  const [sorceDropdown, setSorceDropdown] = useState([]);


  useEffect(() => {
    getStatusDropdownData();
    getBranchDropdownData();
    getallLeadDetails();
    getSorceTypeDropdownData();
  }, []);

  const getallLeadDetails = async () => {
    var response = await getAxios({
      url: DropoutStatusGetDataEndPoint + currentstatus.id + "/",
    });

    if (response !== null) {
      console.log(response.data)
      var tempDetails = {
        name: response.data?.name,
        from_branch: response.data?.branch,
        mobile: response.data?.mobile,
        additional_mobile: response.data?.additional_mobile,
        appointment_date: response.data?.appointment_date,
        dropout_type_name: response.data?.dropout_type_name,
        status: response.data?.status,
        source_type: response.data?.source_type,
        remarks: response.data?.remarks,
      };
      setStatusData(tempDetails);
    }
  };

  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    setStatusData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };

  const isValidForm = (formDataObject) => {
    const err = {};
    Object.entries(formDataObject).forEach(([field, value]) => {
      if (value.length === 0) {
        err[field] = `${field} field is required`;
      } else {
        err[field] = "";
      }
    });

    // Validate mobile number field
    const mobileRegex = /^[0-9]{10}$/; // Assumes a 10-digit mobile number
    if (!mobileRegex.test(formDataObject.mobile)) {
      err['mobile'] = 'Invalid mobile number';
    } else {
      err['mobile'] = '';
    }

    // Validate if "Are You Transfer to Other Branch?" is checked
    if (statusData.from_branch === 29 && isChecked !== 1) {
      err['transfer'] = 'Please confirm transfer to another branch';
    } else {
      err['transfer'] = '';
    }

    err['appointment_date'] = '';
    err['additional_mobile'] = '';
    err['remarks'] = '';

    setErrors((prevState) => ({
      ...prevState,
      ...err,
    }));

    const hasErrors = Object.values(err).some((error) => error.length > 0);

    return !hasErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());

    console.log(formDataObject, "formDataObject")
    if (isValidForm(formDataObject)) {
      console.log("Form data is valid:", formDataObject);

      if (formMode === "update") {
        console.log("statusData", statusData);
        statusData["transfer"] = isChecked
        updateStaff(statusData);

      }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };

  const updateStaff = async (body) => {
    var response = await putAxiosWithToken({
      url: DropoutStatusUpdateEndPoint + currentstatus.id + "/",
      body: body,
    });

    if (response != null) {
      getData();
      onClose();
      toast.success(response.message);
    }
  };

  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: statusDropdownEndPoint,
    });

    if (response !== null) {
      setStatusTypeDropdown(response.data.list);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };


  const getSorceTypeDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeotherListEndPoint,
    });

    if (response !== null) {
      setSorceDropdown(response.data.list);
    }
  };


  const handleCheckboxChange = () => {
    setIsChecked(isChecked === 1 ? 0 : 1);
    setIsSelectOpen(!isSelectOpen);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="w-full h-full p-[30px]">
          <div className="flex gap-[10px] mt-4">
            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">
                Name <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={statusData.name}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.name}</p>
            </div>

            <div className="sm:my-[5px] w-full">
              <p className="text-black text-[15px] font-500 mt-2">
                Schedule Date
              </p>

              {/* <div className="flex gap-[10px] items-center"> */}
              <input
                type="date"
                name="appointment_date"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={statusData.appointment_date}
                onChange={onValueUpdate}
              />
              {/* </div> */}
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">
                Mobile Number <span className="text-red-500">*</span>
              </p>
              <input
                type="number"
                name="mobile"
                id="mobile"
                placeholder=" Mobile Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={statusData.mobile}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.mobile}</p>
            </div>

            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">
                Additional Mobile Number
              </p>
              <input
                type="number"
                name="additional_mobile"
                id="additional_mobile"
                placeholder="additional_mobile"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={statusData.additional_mobile}
                onChange={onValueUpdate}
              />
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">
                Type <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="dropout_type_name"
                id="dropout_type_name"
                placeholder="Type"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={statusData.dropout_type_name}
                onChange={onValueUpdate}
                readOnly
              />
              <p style={{ color: 'red' }}>{errors?.dropout_type_name}</p>
            </div>

            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">
                Branch <span className="text-red-500">*</span>
              </p>
              <select
                placeholder="From Branch"
                id="from_branch"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="from_branch"
                value={statusData?.from_branch}
                onChange={onValueUpdate}
                disabled
              >
                <option value={""}>--Branch Unallocate--</option>
                {branchDropdown.map((option) => (
                  <option key={option.id} value={option.id} className="capitalize">
                    {option.branch_name}
                  </option>
                ))}
              </select>
              <p style={{ color: 'red' }}>{errors?.from_branch}</p>
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">
                Choose a Status:<span className="text-red-500">*</span>
              </p>
              <select
                placeholder="Status"
                className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none "
                name="status"
                value={statusData.status}
                onChange={onValueUpdate}
              >
                <option value={""}>--select status --</option>
                {statusTypeDropdown?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.status_name}
                  </option>
                ))}
              </select>
              <p style={{ color: 'red' }}>{errors?.status}</p>
            </div>


            <div className="sm:my-[5px] w-full">
              <p className="mb-[5px] text-[15px]">Source Type  <span className="text-red-500">*</span></p>
              <select
                placeholder="Source Type"
                id="source_type"
                className="sm:mb-[5px] h-[45px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                name="source_type"
                value={statusData.source_type}
                onChange={onValueUpdate}
                disabled
              // onClick={(e)=> setcampName(e.target.value)}
              >
                <option value={""}>--select source--</option>
                {sorceDropdown.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.source_name}
                  </option>
                ))}
              </select>
              <p style={{ color: 'red' }}>{errors?.source_type}</p>
            </div>
          </div>

          {/* <div className={`${statusData.from_branch === 29 ? "visible" : "hidden"}`}> */}
              <div className="sm:my-[5px] w-full">
                <div>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label>Would you like to transfer to other branch?</label>

                  {isSelectOpen && (
                    <>
                      <select
                        className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none "
                        name="to_branch"
                        value={statusData.to_branch}
                        onChange={(e) => { setTobranchDropdown(e.target.value); onValueUpdate(e) }}
                      >
                        <option value={""}>--select branch--</option>
                        {branchDropdown.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            className="capitalize"
                          >
                            {option.branch_name}
                          </option>
                        ))}
                      </select>
                      <p style={{ color: 'red' }}>{errors?.to_branch}</p>
                    </>
                  )}

                  {statusData.from_branch === 29 && (
                    <p style={{ color: 'red' }}>{errors?.transfer}</p>
                  )}
                </div>
              </div>
            {/* </div> */}

          <div>
            <div className="mt-5 text-black text-[14px] font-500">Remark</div>
            <textarea
             className="mt-5 border-2 border-black-500 p-1"
              id="remarks"
              name="remarks"
              rows="4"
              cols="50"
              value={statusData.remarks}
              onChange={onValueUpdate}
            ></textarea>
          </div>
          <div className="flex gap-[10px]">
            <button
              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer"
            >
              SAVE
            </button>
            <div
              onClick={onClose}
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              CLOSE
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
