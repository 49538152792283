import { createSlice } from '@reduxjs/toolkit'
import { defaultSidebarClassName } from '../appconfig/themeconfig'

const initialState = {
  today: new Date(),
  fullMenu: false,
  sidebarClassName: defaultSidebarClassName,
  tabMenuList: [],
  activeTabMenu: 1,
  isLoading:true,
  action: {
    viewPermit: false,
    addPermit: false,
    editPermit: false,
    deletePermit: false
  },
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setFullmenu: (state, action) => {
      state.fullMenu = action.payload
    },
    setSidebarClassName: (state, action) => {
      state.sidebarClassName = action.payload
    },
    setTabMenuList: (state, action) => {
      state.tabMenuList = action.payload
    },
    setActiveTabMenu: (state, action) => {
      state.activeTabMenu = action.payload
    },
    setActions: (state, action) => {
      state.action = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  }
})

export const { setFullmenu, setSidebarClassName, setTabMenuList, setActiveTabMenu, setIsLoading, setActions } = commonSlice.actions;

export default commonSlice.reducer