import React, { useEffect, useState } from 'react'
import { getAxios, postAxiosWithToken } from '../../../service/apiservice';
import { LeadTypeDataEndPoint, allLeadsTableDataEndPoint, branchListEndPoint, branchTranferListEndPoint, branchtransferstatusdropdownEndPoint, leadsPopUpStatusbasedEndPoint, statusTypeListEndPoint } from '../../../service/api';
import { convertToDate } from '../../../utilities/datetime';
import { convertToTime } from '../../../utilities/time';
import CustomePagination from '../../../components/CustomPagination';
import Modal from '../../../components/Model';
import LeadDropDown from '../../../components/LeadsTableDropDown';
import { useNavigate } from 'react-router-dom';
import Searchbar from '../../../components/SearchBar';
import DatePicker1 from '../../../components/DatePicker1';
import { Popover } from '@headlessui/react';
import Popup from '../../../components/Popup';
import Addincomingform from '../incomingappointment/addincomingform';

const Branchtransfer = () => {

  const [allleadsTableList, setAllleadsTableList] = useState([]);
  // const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentstatus, setCurrentstatus] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [statusTypeDropdown, setStatusTypeDropdown] = useState([]);
  const [LeadsDropdown, setLeadsDropdown] = useState([]);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isIncommingOpen, setIsIncommingOpen] = useState(false);
  const [totalitems, setTotalitems] = useState({});


  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [body, setBody] = useState({
    status: "",
    from_date: "",
    to_date : "",
    branch: Number(localStorage.getItem('branch')),
    search: "",
    page : 1,
    items_per_page : 10,
    lead_type: ""
  });

  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function closeIncommingModal() {
    setIsIncommingOpen(false);
  }

  
  const navigate = useNavigate();

  useEffect(() => {
    getAllLeadsListData();
  }, [body]);


  const getAllLeadsListData = async () => {
    var response = await postAxiosWithToken({
      url: branchTranferListEndPoint  ,
      body: body,
    });

    if (response !== null) {
      setAllleadsTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };
  useEffect(()=>{
    getBranchDropdownData();
    getStatusDropdownData();
    getLeadsDropdownData();

  },[])

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };


  const getLeadsDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadsDropdown(response.data.list);
    }
  };




  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: branchtransferstatusdropdownEndPoint,
    });

    if (response !== null) {
      setStatusTypeDropdown(response.data.list);
    }
  };



    // Filter
    const handleDateFilter = async (e) => {
      if (e.startDate === null && e.endDate === null) {
        setBody((prevState) => ({
          ...prevState,
          from_date: "",
          to_date: "",
          page: 1,
        }));
      } else {
        setBody((prevState) => ({
          ...prevState,
          from_date: e.startDate,
          to_date: e.endDate,
          page: 1,
        }));
      }
  
      setValue(e);
    };
  

    const searchHandler = (e) => {
      setBody((prevState) => ({
        ...prevState,
        search: e.target.value,
        page: 1,
      }));
    };
  
    const pageHandler = (value) => {
      setBody((prevState) => ({
        ...prevState,
        page: value,
      }));
    };
  
    const entriesHandler = (value) => {
      setBody((prevState) => ({
        ...prevState,
        items_per_page: value,
        page: 1,
      }));
    };


  return (
    <div>

<div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
          <div>
            <select
              onChange={(e) => {
                setBody((prevState) => ({
                  ...prevState,
                  status: e.target.value,
                  page: 1,
                }));
              }}
              placeholder="Leads"
              className=" sm:mb-[5px] w-[200px] bg-white border border-gray-300 rounded-lg p-[5px]  text-[#1D1D1F] text-[18px] font-[500px] outline-none"
            >
              <option value={""}>All Leads</option>
              {statusTypeDropdown.map((items) => (
                <option
                  value={items.id}
                  id={items.id}
                  className="text-[16px] text-[#1D1D1F] font-[400] "
                >
                  {items.status}
                </option>
              ))}
            </select>
          </div>

          <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">


          <div className="flex gap-[10px] max-[520px]:mt-5 ">
              <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
                <select
                  placeholder="Leads Type"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="lead_type"
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      lead_type: e.target.value,
                      page: 1,
                    }));
                  }}
                >
                  <option value={""}>--select LeadsType--</option>
                  {LeadsDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.leadtype_name}
                    </option>
                  ))}
                </select>
              </div>
              
            </div>




            

            
            <div className="flex gap-[10px] max-[520px]:mt-5 ">
              <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="branch"
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              </div>
              
            </div>


            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>
        <div className="flex gap-[10px] justify-end">

        

     
        </div>
          </div>
        </div>

        <div className='flex justify-end'> 
        <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              <Searchbar value={body.search} handleChange={searchHandler}  />
            </div>
        </div>






        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
          <table className="w-full">
            <thead className="border bg-secondary text-white">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[200px]">Name</td>
                <td className="p-[10px] min-w-[200px]">Mobile No</td>
                <td className="p-[10px] min-w-[200px]">From Branch</td>
                <td className="p-[10px] min-w-[200px]">To Branch</td>
                <td className="p-[10px] min-w-[200px]">Status</td>
                <td className="p-[10px] min-w-[200px]">Source Type</td>
                <td className="p-[10px] min-w-[200px]">Lead Type</td>
                <td className="p-[10px] min-w-[200px]">Camp/Daily Walkin</td>
                <td className="p-[10px] min-w-[200px]">Appointment Date</td>
                <td className="p-[10px] min-w-[200px]">Created Date</td>
                <td className="p-[10px] min-w-[200px]">Last Update Date</td>
                <td className="p-[10px] min-w-[100px]">Created By</td>
                <td className="p-[10px] min-w-[100px]">Remarks</td>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={6}>
                    Loading...
                  </td>
                </tr>
              ) : allleadsTableList.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={6}>
                    No Data
                  </td>
                </tr>
              ) : (
                allleadsTableList.map((item, index) => (
                  <tr key={item.id} className="border">
                    {console.log("item",item)}
                    <td className="p-[10px]">{item.sno}</td>

                    <td className="p-[10px]">{item.name}</td>

                    <td className="p-[10px]">{item.mobile}</td>
                    <td className="p-[10px]">{item.frombranch_name}</td>
                    <td className="p-[10px]">{item.tobranch_name}</td>
                    <td className="p-[10px] capitalize">
                      <div className="relative">
                        <div
                          className="text-white px-[5px] py-[10px] rounded-[10px] w-[150px]"
                          style={{ backgroundColor: item.status_color }}
                        >
                          <div
                            onClick={() => {
                              setCurrentstatus(item);
                              setIsStatusOpen(true);
                            }}
                            className="flex gap-[10px] justify-center items-center"
                          >
                            {item.status_name}
                            
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-[10px] capitalize">{item.source_name}</td>
                    <td className="p-[10px] capitalize">{item.leadtype_name}</td>

                    <td className="p-[10px] capitalize">{item.camp_name ? item.camp_name : '---'}</td>
                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {/* {convertToDate(item.appointment_date)} */}
                        {item.appointment_date ? item.appointment_date : '0000-00-00'}

                      </p>
                    </td>

                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {convertToDate(item.create_date)}
                      </p>
                    
                    </td>
                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {convertToDate(item.modified_at)}
                      </p>
                    
                    </td>
                    <td className="p-[10px] capitalize">
                      {item.created_by_name}
                    </td>
                    <td className="p-[10px] capitalize">
                      {item.remarks ? item.remarks : '---'}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}
          />

      </div>
        
      
    </div>
  )
}

export default Branchtransfer;
