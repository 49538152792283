import React, { useEffect, useState } from "react";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../service/apiservice";
import {
  LeadTypeDataEndPoint,
  branchListEndPoint,
  excelUploadEndPoint,
  importBranchListEndPoint,
  importLeadDataEndPoint,
} from "../../service/api";
import DragAndDrop from "../Drog&Drop";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

export default function UploadFile({ uploadedFile, setUploadedFile, formData, setFormData }) {
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [leadtypeDropdown, setLeadTypeDropdown] = useState([]);

 const [campDeatilsData,setCampDeatilsData] = useState([])


 const dispatch = useDispatch();


  // console.log("excelSheetUrl", uploadedFile);

  const handleFileUpload = (file) => {
    setUploadedFile(file);
    // You can handle the file upload logic here
  };

  const schema = yup
    .object({
      lead_type: yup.string().required(),
      camp_name: yup.string().required(),
      // branch: yup.string().required(),
      file: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
  });

  useEffect(() => {
    getBranchDropdownData();
    getLeadDropdownData();
  }, []);

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: importBranchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const getLeadDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadTypeDropdown(response.data.list);
    }
  };

 

  

  

  const handleCampDetails = (fieldName, value) => {

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }));

    
  };



  return (
    <div>
      <form id="sheetform">
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-8">
          <div className="sm:my-[5px] ">
            <p className="mb-[5px] text-[15px]">
              Types of Lead <span className="text-red-500">*</span>
            </p>
            <select
              placeholder="Types of Lead"
              className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
              name="lead_type"
              id="lead_type"
              {...register("lead_type")}
              value={formData.lead_type}
              onChange={(e) => handleCampDetails('lead_type', e.target.value)}
            >
              <option value={""}>--select Types of Lead--</option>
              {leadtypeDropdown.map((option) => (
                <option
                  className="capitalize"
                  key={"lead_type" + option.leadtype_name}
                  value={option.id}
                >
                  {option.leadtype_name}
                </option>
              ))}
            </select>
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.lead_type?.message}
            </p>
          </div>
          {/* <div className="sm:my-[5px] ">
            <p className="mb-[5px] text-[15px]">
              Select Branch
               <span className="text-red-500">*</span>
            </p>
            <select
          placeholder="Branch"
          className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
          name="branch"
          id="branch"
          {...register("branch")}
          onChange={(e) => handleCampDetails('branch', e.target.value)}
              value={formData.branch}
            >
              <option value={""}>--select branch--</option>
              {branchDropdown.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="capitalize"
                >
                  {option.branch_name}
                </option>
              ))}
            </select>
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.branch?.message}
            </p>
          </div> */}

          <div className="my-[5px]">
            <p className="mb-[5px] text-[15px]">
              Camp Name <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Camp Name"
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
              name="camp_name"
              id="camp_name"
              {...register("camp_name")}
              onChange={(e) => handleCampDetails('camp_name', e.target.value)}
              value={formData.camp_name}
            />
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.camp_name?.message}
            </p>
          </div>
        </div>

        <DragAndDrop
          onFileUpload={handleFileUpload}
          uploadedFile={uploadedFile}
        />
  
      </form>
    </div>
  );
}
