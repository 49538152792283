import React, { useEffect, useState } from 'react'
import { getAxios, postAxiosWithToken } from '../../../service/apiservice';
import { branchListEndPoint, leadDischargeEndPoint } from '../../../service/api';
import Searchbar from '../../../components/SearchBar';
import DatePicker1 from '../../../components/DatePicker1';
import CustomPagination from '../../../components/CustomPagination';
import { convertToDate } from '../../../utilities/datetime';
import {  useParams } from "react-router-dom";


function Dischargepatient() {

  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [allpatientDischargeTableList, setAllpatientDischargeTableList] = useState([]);
  const [totalitems, setTotalitems] = useState({});

  const {fromDate,toDate} = useParams()
  const selectedBranchFromLocalStorage = localStorage.getItem('dasboardbranch');

  const [value, setValue] = useState({
    startDate: fromDate,
    endDate: toDate,
  });

  const [body, setBody] = useState({
    search: "",
    from_date: fromDate || "",
    to_date: toDate || "",
    page: 1,
    branch: selectedBranchFromLocalStorage || localStorage.getItem("branch") ,
    items_per_page: 10

  });

  useEffect(()=>{
    getDischargepatientListData(body);

  },[body])
  useEffect(()=>{
    getBranchDropdownData();
  },[])

    const getDischargepatientListData = async () => {
        var response = await postAxiosWithToken({
          url: leadDischargeEndPoint,
          body: body,
        });
    
        if (response !== null) {
          setAllpatientDischargeTableList(response.data.list)
          setTotalPages(response.data.total_pages);
          setTotalitems(response.data);
          setIsLoading(false);
        }
      };

      const getBranchDropdownData = async () => {
        var response = await getAxios({
          url: branchListEndPoint,
        });
    
        if (response !== null) {
          setBranchDropdown(response.data.list);
        }
      };




      const handleDateFilter = async (e) => {
        console.log(e);
        if (e.startDate === null && e.endDate === null ){
          setBody((prevState) => ({
            ...prevState,
            from_date: "",
            to_date: "",
            page: 1,
          }));
        }else{
          setBody((prevState) => ({
            ...prevState,
            from_date: e.startDate,
            to_date: e.endDate,
            page: 1,
          }));
        }
        
        setValue(e)
      };


      const searchHandler = (e) => {
        setBody((prevState) => ({
          ...prevState,
          search: e.target.value,
          page: 1,
        }));
      };
    
      const pageHandler = (value) => {
        setBody((prevState) => ({
          ...prevState,
          page: value,
        }));
      };
    
    const entriesHandler = (value) => {
      setBody((prevState) => ({
        ...prevState,
        items_per_page: value,
        page: 1,
      }));
    };
  return (
    <div>

<div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[650px]:block max-[650px]:mb-[50px] ">
        <p className=" sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
          Patient
        </p>

        <div className="flex gap-[10px] max-[520px]:mt-5 max-[575px]:block ">

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

         
        </div>
      </div>

      <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div className="h-[40px] w-[300px] max-[425px]:mt-[5px] max-[425px]:w-full ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
            <td className="p-[10px]">S.No</td>
            <td className="p-[10px] min-w-[200px]">File no</td>
            <td className="p-[10px] min-w-[200px]">Name</td>
              <td className="p-[10px] min-w-[200px]">Spouse Name</td>
              <td className="p-[10px] min-w-[200px]">Patient Mobile</td>
              <td className="p-[10px] min-w-[200px]">Branch Name</td>
              <td className="p-[10px] min-w-[200px]">Procedure Type Name</td>
              <td className="p-[10px] min-w-[200px]">In Date</td>
              <td className="p-[10px] min-w-[200px]">Out Date</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={7}>
                  Loading...
                </td>
              </tr>
            ) : allpatientDischargeTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={7}>
                  No Data
                </td>
              </tr>
            ) : (
              allpatientDischargeTableList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{item.sno}</td>
                  <td className="p-[10px] capitalize">{ item.file_no != null ? item.file_no :"----"}</td>
                  

                  <td className="p-[10px] capitalize">{item.patient_name}</td>
                  <td className="p-[10px] capitalize">{item.spouse_name}</td>
                  <td className="p-[10px] capitalize">{item.patient_mobile}</td>
                  <td className="p-[10px] capitalize">{item.branch_name}</td>
                  <td className="p-[10px] capitalize">{item.procedure_type_name}</td>
                  
                  <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        { item.create_date != "0000-00-00" ? convertToDate(item.in_date) :"0000-00-00"}
                      </p>
                    </td>
                  <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        { item.create_date != "0000-00-00" ? convertToDate(item.out_date) :"0000-00-00"}
                      </p>
                    </td>
                    {/* <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                      { item.modified_at != "0000-00-00 00:00" ? convertToDate(item.modified_at) :"0000-00-00"}
                      
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      { item.modified_at != "0000-00-00 00:00" ? convertToTime(item.modified_at) :"0000-00-00"}
                      </p>
                    </td> */}

 

                  

                  
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>


      <div className="mb-[50px]">
      <CustomPagination 
        totalPages={totalPages}
        currentPage={body.page}
        setCurrentPage={pageHandler}
        entries={body.items_per_page}
        entriesHandler={entriesHandler}
        totalitmes={totalitems.total_items}
      
      
      />
      </div>
     
    </div>
  )
}

export default Dischargepatient
