import LogoImage from "../../assets/images/Shiplogologin.svg";
import { logoutEndPoint } from "../../service/api";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDark, setMenuOption } from "../../redux/themeconfigslice";
import Avathar from "../../assets/images/header-avatar.svg";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../breadcrumbs";
import { datetimetodate } from "../../utilities/datetime";
import Popup from "../../components/Popup";
import Addincomingform from "../../pages/leads/incomingappointment/addincomingform";

export default function Header({ logoVisible = false }) {
  const [loginDetails, setLoginDetails] = useState({});
  const [isIncommingOpen, setIsIncommingOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("authenticated")) {
      setLoginDetails(JSON.parse(localStorage.getItem("login")));
    }
  }, []);

  const location = useLocation();

  const isDark = useSelector((state) => state.themeConfig.isDark);
  const { today, fullMenu } = useSelector((state) => state.common);

  function closeIncommingModal() {
    setIsIncommingOpen(false);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    let darkStorage = JSON.parse(localStorage.getItem("dark"));
    let layoutStorage = localStorage.getItem("layout");

    if (darkStorage !== null) {
      dispatch(setIsDark(darkStorage));
    }

    if (layoutStorage !== null) {
      dispatch(setMenuOption(layoutStorage));
    }
  }, []);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDark]);

  return (
    <>
      <div className="flex justify-between px-[15px] py-[15px] border-b shadow-lg">
        <div>
          {location.pathname === "/" ? (
            <div>
              <p className="text-[#2E2E2E] font-[500] text-[14px] min-[360px]:text-[18px] min-[700px]:text-[24px] min-[360px]:leading-[29px] capitalize">
                Welcome to SHIP!
              </p>
              <p className="text-secondary font-[400] text-[10px] min-[360px]:text-[12px] min-[700px]:text-[14px] min-[360px]:leading-[20px] capitalize">
                {datetimetodate(today)}
              </p>
            </div>
          ) : (
            <BreadCrumbs />
          )}
        </div>

        <div className={`flex gap-2 items-center `}>
          <div
            onClick={() => {
              document.getElementById("menubar").className =
                "fixed top-0 left-0 z-[5] block lg:block";
            }}
            className={`${logoVisible ? "md:hidden" : "lg:hidden"}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="48"
                d="M88 152h336M88 256h336M88 360h336"
              />
            </svg>
          </div>
          <div
            className={`h-[55px] w-[150px] ${logoVisible ? "block" : "hidden"}`}
          >
            <img src={LogoImage} alt="no-logo" />
          </div>
        </div>

        <div className="flex  gap-[25px] items-center">
          {/* <button
                
                onClick={() => { localStorage.setItem('incomming_form_mode', 'create');localStorage.setItem('allleades_branch',"alllead"); setIsIncommingOpen(true); }}
                className="w-[130px] capitalize bg-primary text-white rounded-lg p-[8px] h-fit font-[500] text-[17px] outline-none border-none"
              >
                <span className="flex justify-center items-center gap-[5px] text-[15px] ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16 19H22M19 16V22M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  Add Leads
                </span>
              </button> */}




          {/* <div className="hidden min-[520px]:block bg-primary text-white p-[6px] rounded-lg searchBox">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 256 256"
            >
              <g
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              >
                <circle cx="128" cy="128" r="112" />
                <path d="M 79.999992,128 H 176.0001" />
                <path d="m 128.00004,79.99995 v 96.0001" />
              </g>
            </svg>
          </div> */}
         

          {/* <div className="relative hidden min-[520px]:block">
                    <div className=" border border-[#E2E8F0] text-secondary p-[6px] rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M13 3a1 1 0 1 0-2 0v.75h-.557A4.214 4.214 0 0 0 6.237 7.7l-.221 3.534a7.377 7.377 0 0 1-1.308 3.754a1.617 1.617 0 0 0 1.135 2.529l3.407.408V19a2.75 2.75 0 1 0 5.5 0v-1.075l3.407-.409a1.617 1.617 0 0 0 1.135-2.528a7.376 7.376 0 0 1-1.308-3.754l-.221-3.533a4.214 4.214 0 0 0-4.206-3.951H13V3Zm-2.557 2.25a2.714 2.714 0 0 0-2.709 2.544l-.22 3.534a8.877 8.877 0 0 1-1.574 4.516a.117.117 0 0 0 .082.183l3.737.449c1.489.178 2.993.178 4.482 0l3.737-.449a.117.117 0 0 0 .082-.183a8.877 8.877 0 0 1-1.573-4.516l-.221-3.534a2.714 2.714 0 0 0-2.709-2.544h-3.114Zm1.557 15c-.69 0-1.25-.56-1.25-1.25v-.75h2.5V19c0 .69-.56 1.25-1.25 1.25Z" clip-rule="evenodd" /></svg>
                    </div>
                    <div className="w-[10px] h-[10px] bg-primary rounded-full absolute right-[-3px] top-[-3px]"></div>
                </div> */}

          {/* <div className="hidden min-[520px]:block border border-[#E2E8F0] text-secondary p-[6px] rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="currentColor" d="M8 15c-3.86 0-7-3.14-7-7s3.14-7 7-7s7 3.14 7 7s-3.14 7-7 7ZM8 2C4.69 2 2 4.69 2 8s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6Z" /><path fill="currentColor" d="M8 4.5c-1.11 0-2 .89-2 2h1c0-.55.45-1 1-1s1 .45 1 1c0 1-1.5.88-1.5 2.5h1c0-1.12 1.5-1.25 1.5-2.5c0-1.11-.89-2-2-2Z" /><circle cx="8" cy="11" r=".62" fill="currentColor" /><circle cx="6.5" cy="6.5" r=".5" fill="currentColor" /><circle cx="8" cy="9" r=".5" fill="currentColor" /></svg>
                </div> */}
       <div className="flex items-center gap-[5px]">

          <div className="w-[30px] min-[520px]:w-[44px] h-[30px] min-[520px]:h-[44px]  ">
            <img src={Avathar} alt="avathar" />
          </div>

          <div className="hidden  min-[420px]:block text-[13px] md:text-[18px]">
            <p className="capitalize font-bold text-[15px]">
              <span className="text-[15px] font-bold">
                {loginDetails.username}
              </span>
            </p>
            {/* <p className="capitalize font-bold text-[15px]">User Role : <span className="text-[13px] font-medium">{loginDetails.user_role}</span></p> */}
          </div>
          </div>
        </div>
    </div>

        {/* <div>
          <Popup
            title={"Create InComming Appointment"}
            setIsOpen={setIsIncommingOpen}
            isOpen={isIncommingOpen}
            closeModal={closeIncommingModal}
          >
            <Addincomingform onClose={closeIncommingModal} />
          </Popup>
        </div> */}
    </>
  );
}
